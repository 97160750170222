import Loading from '@aurora/shared-client/components/common/Loading/Loading';
import useCommunitySsoProperties from '@aurora/shared-client/components/community/useCommunitySsoProperties';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';
import useIsomorphicRedirect from '@aurora/shared-client/components/useIsomorphicRedirect';
import useIsomorphicRedirectByUrl from '@aurora/shared-client/components/useIsomorphicRedirectByUrl';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext } from 'react';
import BasePage from '../../../components/page/BasePage/BasePage';
import useTranslation from '../../../components/useTranslation';
import useEndUserNextPage from '@aurora/shared-client/routes/useEndUserNextPage';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';

/**
 * Login Page presents the user with username and password fields to sign in to the community.
 *
 * @author Medha Smriti
 */
const LoginPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage, loading } = useTranslation(EndUserComponent.LOGIN_PAGE);
  const { canAccess, community } = useContext(AppContext);
  const tenant = useContext(TenantContext);
  const { ssoAllowNormalSignon, multiAuthEnabled } = tenant.publicConfig;
  const { data: ssoPropsData, loading: ssoPropsLoading } = useCommunitySsoProperties(module);
  const { community: communityData } = ssoPropsData || {};
  const ssoEnabled = checkPolicy(communityData?.ssoProperties?.ssoEnabled);
  const { nextUrl, nextRoute } = useEndUserNextPage();
  const { getSsoAuthUrl } = useAuthFlow();
  const finalUrl = getSsoAuthUrl(nextUrl);
  const { router } = useEndUserRoutes();
  const { isAnonymous } = useRegistrationStatus();

  function getPageTitle(): string | undefined {
    if (!canAccess || community?.title == null) {
      return loading ? null : formatMessage('privateTitle');
    }
  }

  // Redirect to SSO/Multi-Auth login page when SSO enabled and user is anonymous
  // unless there are multiple and the SSO auth URL is for the Login Page.
  useIsomorphicRedirectByUrl(
    !ssoPropsLoading &&
      (ssoEnabled || multiAuthEnabled) &&
      !ssoAllowNormalSignon &&
      isAnonymous &&
      !!finalUrl &&
      router.getRouteAndParamsByPath(finalUrl)?.route != EndUserPages.LoginPage,
    finalUrl
  );

  // Redirect to Community page is user is already logged in
  useIsomorphicRedirect(!isAnonymous, nextRoute);

  if (ssoPropsLoading) {
    return <Loading />;
  }

  return <BasePage pageId={EndUserPages.LoginPage} pageTitle={getPageTitle()} />;
};

export default LoginPage;
